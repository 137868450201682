import {
  CHECKBOX_LEF,
  L_PHONE_MASK_INPUT, PASSWORD_INPUT, SELECT_INPUT, TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'
import { can } from '@core/libs/acl/utils'

export default function config() {
  const ACCESS_ABILITY_FOR_SYSTEM_USERS = { action: 'change', subject: 'System Users' }

  // Table Handlers
  const tableColumns = [
    { key: 'actions', label: '', thStyle: { width: can(ACCESS_ABILITY_FOR_SYSTEM_USERS.action, ACCESS_ABILITY_FOR_SYSTEM_USERS.subject) ? '10%' : '0%' } },
    { key: 'name', label: 'Name' },
    { key: 'role', label: 'Role' },
    { key: 'warehouse', label: 'Default Location' },
    { key: 'status', label: 'Status' },
  ]
  const tableTrashColumns = [
    { key: 'actions', label: '', thStyle: { width: can(ACCESS_ABILITY_FOR_SYSTEM_USERS.action, ACCESS_ABILITY_FOR_SYSTEM_USERS.subject) ? '10%' : '0%' } },
    { key: 'name', label: 'Name' },
    { key: 'status', label: 'Status' },
  ]
  const fields = {
    firstname: {
      type: TEXT_INPUT,
      label: 'First Name',
      rules: 'required',
    },
    lastname: {
      type: TEXT_INPUT,
      label: 'Last Name',
      rules: 'required',
    },
    email: {
      type: TEXT_INPUT,
      label: 'Email Address',
      rules: 'required|email',
    },
    phone: {
      type: L_PHONE_MASK_INPUT,
      label: 'Phone Number',
      // rules: 'required',
      // options: {
      //   options: {
      //     blocks: [0, 3, 3, 4, 6],
      //     delimiter: '(',
      //     delimiters: ['(', ')', '-', ' ext '],
      //     numericOnly: true,
      //   },
      // },
      placeholder: 'Type...',
    },
    // phone_no_object: {
    //   type: L_PHONE_MASK_INPUT,
    //   label: 'Phone Number',
    //   // rules: 'required',
    //   // options: {
    //   //   options: {
    //   //     blocks: [0, 3, 3, 4, 6],
    //   //     delimiter: '(',
    //   //     delimiters: ['(', ')', '-', ' ext '],
    //   //     numericOnly: true,
    //   //   },
    //   // },
    //   placeholder: 'Type...',
    // },
    username: {
      type: TEXT_INPUT,
      label: 'New User Login',
      rules: 'required',
    },
    warehouse_id: {
      type: SELECT_INPUT,
      label: 'Default Location',
      store: 'warehouseList',
      rules: 'required',
      options: {
        label: 'label',
      },
    },
    title: {
      type: TEXT_INPUT,
      label: 'Title',
      rules: 'required',
    },
    role_id: {
      type: SELECT_INPUT,
      store: 'roleList',
      rules: 'required',
      label: 'Role',
      options: {
      },
    },
    password: {
      type: PASSWORD_INPUT,
      label: 'Password',
      rules: 'required|min:5',
      placeholder: '••••••••',
    },
    setting_notifications_id: {
      type: SELECT_INPUT,
      fParams: { is_active: 1 },
      fSearchParams: { is_active: 1 },
      label: 'Default Notification Profile',
      store: 'settingNotificationList',
      options: {
        value: 'id',
        label: 'label',
      },
      rules: 'required',
    },
    password_confirmation: {
      type: PASSWORD_INPUT,
      label: 'Retype Password',
      rules: 'required|confirmed:password',
      placeholder: '••••••••',

    },
    status: {
      type: CHECKBOX_LEF,
      label: 'Active',
      options: {
        'unchecked-value': 'inactive',
        value: 'active',
      },
    },
  }
  const setObjectOfPhoneMusk = (mask, formName, fieldName) => {
    if (!mask) return

    // eslint-disable-next-line no-param-reassign
    formName[fieldName] = mask
  }
  return {
    fields,
    tableColumns,
    tableTrashColumns,
    ACCESS_ABILITY_FOR_SYSTEM_USERS,
    setObjectOfPhoneMusk,
  }
}
